<style src="./RadioCheck.scss" lang="scss" scoped></style>

<template>
	<label class="checkBox__wrapper" :class="{ 'cursor-default': disabled }">
		<span class="checkboxLabel">
			{{ label }}
		</span>
		<input
			type="radio"
			:checked="checked"
			@change="$emit('change', $event.target.value)"
			:disabled="disabled"
			:value="radioValue"
			:name="radioName"
		/>
		<span class="checkmark"></span>
	</label>
</template>

<script>
export default {
	name: 'CustomRadioCheck',
	props: {
		checked: Boolean,
		label: String,
		disabled: Boolean,
		radioValue: Number,
		radioName: String,
	},
};
</script>
