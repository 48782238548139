import DateTimePicker from '@/components/DateTimePicker/DateTimePicker.vue';
import { formatToStandardDate } from '../../../store/modules/DateTime';

export default {
	components: {
		DateTimePicker,
	},
	props: {
		counterDate: {
			type: String,
		},
	},
	data() {
		return {
			countDownDate: undefined,
			newDate: '',
		};
	},
	watch: {
		counterDate(newVal) {
			this.countDownDate = newVal;
		},
	},
	methods: {
		async onDatePickerChange(data) {
			this.newDate = formatToStandardDate({
				date: data.dateSelected.selectedDate,
				hour: data.hourSelected,
				minute: data.minuteSelected,
			});
		},
		onSelectNoClick() {
			this.$bvModal.hide('edit-countdown-modal');
		},
		onSelectYesClick() {
			this.$emit('on-rechange', this.newDate);
			this.$bvModal.hide('edit-countdown-modal');
		},
	},
};
