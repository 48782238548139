import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import ItemList from '@/components/ItemList/ItemList.vue';
import CreateEditItem from '@/components/CreateEditItem/CreateEditItem.vue';
import CountDown from '@/components/CountDown/CountDown.vue';
import OptionMenu from '@/components/OptionsMenu/OptionMenu.vue';
import DateTimePicker from '@/components/DateTimePicker/DateTimePicker.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import EditCountDownModal from '@/components/Modal/EditCountDown/EditCountDown.vue';
import RadioCheck from '@/components/RadioCheck/RadioCheck.vue';

import socketClient from '@/services/SOCKET';
import TransferService from '@/services/API/transfer.service';
import ReProposalService from '@/services/API/reproposal.service';
import ReProposalGeneralService from '@/services/API/reproposal_general.service';
import EstimateParticularService from '@/services/API/estimate_particular.service';
import AddCostDetailService from '@/services/API/add_cost_details.service';

import { sendNotify, toLineMessageTemplate } from '@/utilities';
import CommonConst from '@/constants/CommonConst';
import { getFileExtention, getFileName } from '@/store/modules/File';
import { checkMobileScreen } from '@/store/modules/ScreenWidth';
import ProposalConst from '@/constants/ProposalConst';

import updateProjectStatusMixin from '@/mixin/updateProjectStatus';
import emitSoundMixin from '@/mixin/emitSound';
import downloadPdfMixin from '../../../mixin/downloadPdfMixin';

import moment from 'moment';
import { mapState } from 'vuex';

const RE_PROPOSAL_MODE_NUMB = 0;
const RE_REVISION_MODE_NUMB = 1;
const DESCRIPTION_LABEL = '画像をアップロードしてご提案の準備をしましょう';
const DESCRIPTION_SKIP = '今すぐ提案';
const UNSAVE_NUMBER = 0;
const SAVED_NUMBER = 1;
const PROPOSAL_SELECTIVE_NOTI_CONTENTS = checkMobileScreen()
	? '「 再提案 」 をクライアントに\n送信します'
	: '「 再提案 」 をクライアントに送信します';
const REVISION_SELECTIVE_NOTI_CONTENTS = '「 修正 」 をクライアントに送信します';
const OPEN_MENU_NOTI_CONTENTS = '「 選択メニュー 」 を表示しますか？';

const CONFIRM_DELETE_CONTENTS = '削除してもよろしいですか？';

const DEFAULT_ITEM = {
	title: undefined,
	isColorChanged: false,
	itemId: undefined,
	commentContent: undefined,
	dateData: undefined,
	gridCols: undefined,
	gridStatus: undefined,
	showDateData: true,
	showTitle: true,
	statusIcon: 'QuestionStatus.png',
	isShowIcon: false,
	fileType: '',
	fileName: '',
};

const SELECTIVE_MODALS = {
	directModal: {
		type: 'direct-modal',
		content: '「 納品 」に進みますか？',
	},
	reproposalModal: {
		type: 'reproposal-modal',
		content: '「 再提案 」に進みますか？',
	},
	revisionModal: {
		type: 'revision-modal',
		content: '「 修正作業 」に進みますか？',
	},
};
export default {
	name: 'ReProposalScreen',
	mixins: [updateProjectStatusMixin, emitSoundMixin, downloadPdfMixin],
	props: {
		mobileLiveScreenMinimum: Boolean,
	},
	components: {
		ScreenHeader,
		ProjectDeadline,
		SaveButtonArea,
		BottomButton,
		ItemList,
		CreateEditItem,
		CountDown,
		OptionMenu,
		DateTimePicker,
		NotificationModal,
		EditCountDownModal,
		RadioCheck,
	},
	data() {
		return {
			screenIcon: '',
			screenName: CommonConst.RE_PROPOSAL_SCREEN_NAME,
			isShowSaveButton: true,
			revisionButtonName: '修正',
			isOpen: false,
			isSelect: false,
			proposalContent: {},
			proposalList: [],
			uploadImageKey: '',
			proposalData: [],
			expiration: 31536000,
			selectProposal: {},
			deliveryDirectLink: 'delivery',
			addcostDirectLink: 'addcost',
			middleContent: ' 様 ／ ',
			showOptionMenu: false,
			counterDate: new Date().toDateString(),
			editCounterDate: new Date().toDateString(),
			hourSelected: '',
			minuteSelected: '',
			proposalStatus: '',
			deadlineEditAble: true,
			sendContentModalId: 'proposal-selective-modal',
			sendContentModalContents: '',
			openMenuModalId: 'open-menu-required-modal',
			openMenuModalContents: '',
			selectiveModalId: 'proposal-selective-general',
			currentSelectiveModal: {},
			allowAdditions: true,
			showFullOptionMenu: false,
			subContents: '「選択メニュー」はクライアントにも表示されます',
			descriptionLabel: '',
			descriptionSkip: '',
			sendItemBtnName: '',
			revisionDeadlineShow: false,
			proposalDeadlineShow: false,
			lastAddCostItemId: '',
			mailContent: '',
			countdownHour: '',
			countdownMinute: '',
			countdownDate: '',
			countdownFlag: false,
			isCountingDown: true,
			screenMode: undefined,
			isShowDeleteButton: false,
			mailTitle: '',
			subject: '',
			isSendCreator: false,

			confirmDeleteModalContents: CONFIRM_DELETE_CONTENTS,
			confirmDeleteQuestionModalId: 'confirm-delete-question-modal',
			currentDeletingQuestion: {},

			revisionFlag: 0,

			pdfPreviewer: {
				url: '',
			},
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'appLogo',
			'appLogoPlain',
		]),
		isSaveButtonDisabled() {
			return this.proposalList.length === 0 ? true : false;
		},
		showSendContentButton() {
			if (
				this.proposalList.length === 0 ||
				this.minuteSelected === '' ||
				this.hourSelected === '' ||
				this.isCountingDown
			) {
				return false;
			} else {
				if (
					this.proposalStatus === CommonConst.SCREEN_STATUS.SEND ||
					this.proposalStatus === CommonConst.SCREEN_STATUS.FINISH ||
					this.proposalStatus === CommonConst.SCREEN_STATUS.REVISION ||
					this.showOptionMenu
				) {
					// To check while in onRevison's status, proposal list has 1 proposal haven't been saved
					console.log(
						'djfhdsakjdfhjasdlkfjsaldfjlksafa',
						this.proposalList,
						this.proposalList.filter((proposal) => proposal.dateData === '').length > 0
					);
					return (
						this.proposalList.filter((proposal) => proposal.dateData === '').length > 0
					);
				}
				return true;
			}
		},

		/**
		 * Show SentContentButton when check '修正対応しない
		 * Because of showSendContentButton logic is so complicated
		 */
		showSendContentButtonExtra() {
			if (this.proposalStatus === CommonConst.SCREEN_STATUS.FINISH) {
				return false;
			} else {
				return (
					!this.isRevisionEnabled &&
					!this.isRevisionDeadlineInit &&
					this.proposalList.length > 0 &&
					!this.isCountingDown &&
					!this.showOpenMenuButton &&
					!this.showOptionMenu
				);
			}
		},

		showOpenMenuButton() {
			if (
				this.proposalStatus === CommonConst.SCREEN_STATUS.SEND &&
				!this.showOptionMenu
				// && !this.isCountingDown
			) {
				return true;
			} else if (this.showOptionMenu) {
				return false;
			} else {
				return false;
			}
		},
		showDeadlineTittleDescr() {
			return !(
				(this.hourSelected || this.hourSelected === 0) &&
				this.minuteSelected &&
				this.isRevisionDeadlineInit
			);
		},
		destinations() {
			return [this.clientInfo['email_address'], this.creatorInfo['email_address']];
		},
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}reproposal`
						: `${this.projectInfo['process_client_url']}reproposal`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}reproposal`
					: `${this.projectInfo['process_client_url']}reproposal`,
			});
		},

		/* eslint-disable no-mixed-spaces-and-tabs */

		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  };
		},

		/**
		 * Indicate no revision radio check is checked
		 * @returns {Boolean}
		 */
		isRevisionDeadlineInit() {
			return this.revisionFlag === ProposalConst.REVISION_INIT_NUM;
		},

		/**
		 * Indicate continue with revision or not
		 * Require once is checked
		 * @returns {Boolean}
		 */
		isRevisionEnabled() {
			if (this.revisionFlag === ProposalConst.REVISION_ENABLE_NUM) return true;
			else if (this.revisionFlag === ProposalConst.REVISION_DISABLE_NUM) return false;
			else return false;
		},
	},

	watch: {
		/**
		 *
		 * @param {Boolean} newVal
		 */
		isRevisionEnabled(newVal) {
			this.showFullOptionMenu = newVal;

			if (!newVal) {
				this.hourSelected = '';
				this.minuteSelected = '';
			}
		},

		revisionFlag(newRevisionFlag) {
			console.log({ newRevisionFlag });
		},
	},

	methods: {
		onDownloadPdfFileFromParent(pdfUrl) { 
			this.pdfPreviewer = pdfUrl
			this.onDownloadPdfFile();
		},

		onReChangeCounterClick() {
			this.$bvModal.show(`edit-countdown-modal`);
			this.editCounterDate = this.counterDate;
		},

		async onReChangeCounter(newCounter) {
			this.$store.commit('setIsAppProcessing', true);

			let newCounterDate = new Date(moment(newCounter)).toString();
			this.counterDate = newCounterDate;
			await this._updateAddCostDetails(this.lastAddCostItemId, {
				reproposal_date: newCounterDate,
			});

			this.$store.commit('setIsAppProcessing', false);
		},

		onDeliveryBtnClick() {
			this.currentSelectiveModal = SELECTIVE_MODALS.directModal;
			this.$bvModal.show(`${this.selectiveModalId}`);
		},

		onRevisionBtnClick() {
			this.currentSelectiveModal = SELECTIVE_MODALS.revisionModal;
			this.$bvModal.show(`${this.selectiveModalId}`);
		},

		onReProposalClick() {
			this.currentSelectiveModal = SELECTIVE_MODALS.reproposalModal;
			this.$bvModal.show(`${this.selectiveModalId}`);
		},

		onHandleTimeOut() {
			if (this.proposalDeadlineShow) this.proposalDeadlineShow = false;
			setTimeout(() => {
				this.isCountingDown = false;
				this.descriptionSkip = '';
			}, 500);
		},
		onHandleTimeRunning() {
			if (!this.proposalDeadlineShow) this.proposalDeadlineShow = true;
			this.isCountingDown = true;
			this.descriptionSkip = DESCRIPTION_SKIP;
		},
		onHandleTimeSkip() {
			let days,
				hours,
				minutes = this.$refs.countDown.getCountDown();
			if (days > 0 || hours > 0 || minutes > 0) {
				this.onReChangeCounter(moment());
				this.$refs.countDown.skipCountDown();
				this.onHandleTimeOut();
			}
			this.descriptionSkip = '';
		},
		onDeleteQuestion(data) {
			console.log({ question: data });
			this.currentDeletingQuestion = data;
			this.$bvModal.show(`${this.confirmDeleteQuestionModalId}`);
		},

		async onRevisionRadioChange(value) {
			this.revisionFlag = Number(value);
			this.$refs.revisionDatetimePicker.resetData();

			await this._updateReProposalGeneral(this.projectId, {
				revision_flag: Number(value),
			});
		},

		async onConfirmDeleteQuestion() {
			this.$store.commit('setIsAppProcessing', true);

			console.log('confirm delete question');
			let question = this.currentDeletingQuestion;

			this.proposalList = this._removeItemOfArray(this.proposalList, question.index);

			await this.deleteProposal(question.questionId);

			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'delete_reproposal_question',
					content: question.questionId,
				},
				room: this.projectId,
			});

			this.currentDeletingQuestion = undefined;

			this.$store.commit('setIsAppProcessing', false);
		},

		_removeItemOfArray(array, index) {
			let originArray = [...array];
			originArray.splice(index, 1);
			return originArray;
		},

		async onConfirmRequire() {
			this.$store.commit('setIsAppProcessing', true);

			this.emitMixinProcessingSound({ isSocketIncluded: true });

			if (
				this.currentSelectiveModal.type &&
				this.currentSelectiveModal.type === 'direct-modal'
			) {
				await this.updateProjectStatus(
					this.projectId,
					CommonConst.SCREEN_ID.REPROPOSAL + 1
				);

				await this._updateReProposalGeneral(this.projectId, {
					status: CommonConst.SCREEN_STATUS.FINISH,
					screen_mode: RE_PROPOSAL_MODE_NUMB,
				});

				await this._getAllReProposalData(this.projectId);

				let confirmInfo = {
					screenId: CommonConst.SCREEN_ID.REPROPOSAL,
					confirmInfo: true,
				};
				this.$emit(
					'on-change-proposal-name',
					CommonConst.RE_PROPOSAL_SCREEN_NAME,
					CommonConst.SCREEN_ID.REPROPOSAL
				);
				this.$emit('on-send-confirm-info', confirmInfo);
				socketClient.send('proposal_creator_direct', {
					userId: this.userId,
					projectId: this.projectId,
					content: {
						directLink: this.deliveryDirectLink,
						currentLink: 'reproposal',
					},
				});
				// send mail to client case 52
				this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］提案が決定しました`;
				this.mailContent = `提案が決定しました。\n納品に進みます。`;
				this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}] 提案が決定しました`;
				this.isSendCreator = true;
				sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
					this.projectInfo['responsible_user'],
				]);
				this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 提案が決定しました`;
				this.mailContent = `提案が決定しました。\n納品に進みます。`;
				this.subject = `【CoMoDe】[${this.projectInfo['project_name']}] 提案が決定しました`;
				this.isSendCreator = false;
				sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
					this.projectInfo['client_id'],
				]);

				this.$router.push(this.deliveryDirectLink);
				this.$emit('on-update-project-status', CommonConst.SCREEN_ID.DELIVERY);
			}

			if (
				this.currentSelectiveModal.type &&
				this.currentSelectiveModal.type === 'reproposal-modal'
			) {
				await this.updateProjectStatus(this.projectId, CommonConst.SCREEN_ID.ADDCOST);
				this.$emit('on-update-project-status', CommonConst.SCREEN_ID.ADDCOST);

				await this._updateReProposalGeneral(this.projectId, {
					status: CommonConst.SCREEN_STATUS.FINISH,
					screen_mode: RE_PROPOSAL_MODE_NUMB,
				});

				await this._getAllReProposalData(this.projectId);
				await this._updateAddCostDetails(this.lastAddCostItemId, {
					status: 'onCreateNew',
				});
				let confirmInfo = {
					screenId: CommonConst.SCREEN_ID.ADDCOST,
					confirmInfo: true,
					isReproposal: true,
				};
				this.$emit(
					'on-change-proposal-name',
					CommonConst.RE_PROPOSAL_SCREEN_NAME,
					CommonConst.SCREEN_ID.REPROPOSAL
				);
				this.$emit('on-send-confirm-info', confirmInfo);
				socketClient.send('creator_direct_to_addcost', {
					userId: this.userId,
					projectId: this.projectId,
					content: this.addcostDirectLink,
				});
				// send mail to client case 51
				this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］再提案を選択しました`;
				this.mailContent = `再提案に進みます。\n納品日・追加費用・提案日・詳細を\n決定してください。`;
				this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}] 再提案を選択しました`;
				this.isSendCreator = true;
				sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
					this.projectInfo['responsible_user'],
				]);
				this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 再提案を選択しました`;
				this.mailContent = `再提案に進みます。\n納品日・追加費用・提案日・詳細を\n決定してください。`;
				this.subject = `【CoMoDe】[${this.projectInfo['project_name']}] 再提案を選択しました`;
				this.isSendCreator = false;
				sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
					this.projectInfo['client_id'],
				]);

				// When change to AddCost turn on CoCreate Sharing Mode
				this.$store.commit('setIsShareCoCreate', true);

				this.$router.push(this.addcostDirectLink);
				this.$emit('on-side-bar-left-sub-proposal');
			}

			if (
				this.currentSelectiveModal.type &&
				this.currentSelectiveModal.type === 'revision-modal'
			) {

				// formattedTime HH時mm分
				let selectedDate = moment().set({ hours: this.hourSelected, minutes: this.minuteSelected, seconds: 0 });
				// let formattedTime = selectedDate.format('H 時 mm 分');
				let formattedTime = `${this.hourSelected} 時 ${this.minuteSelected} 分`;

				let date_end = new Date();
				date_end.setHours(date_end.getHours() + this.hourSelected);
				date_end.setMinutes(date_end.getMinutes() + this.minuteSelected);
				console.log('date_end', date_end, this.hourSelected, this.minuteSelected);
				let updateProposalGeneralContent = {
					status: CommonConst.SCREEN_STATUS.REVISION,
					screen_mode: RE_REVISION_MODE_NUMB,
					countdown_flag: 1,
					date_end: moment(date_end).format('YYYY-MM-DD HH:mm:ss'),
				};
				console.log('updateProposalGeneralContent', updateProposalGeneralContent);
				this.$emit(
					'on-change-proposal-name',
					CommonConst.RE_REVISION_SCREEN_NAME,
					CommonConst.SCREEN_ID.REPROPOSAL
				);
				await this._updateReProposalGeneral(
					this.projectId,
					updateProposalGeneralContent
				);

				await this._getAllReProposalData(this.projectId);
				socketClient.send('update_proposal', {
					projectId: this.projectId,
					userId: this.userId,
					content: {
						status: true,
						newName: CommonConst.RE_REVISION_SCREEN_NAME,
						currentLink: 'reproposal'
					},
				});
				this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］修正を選択しました`;
				this.mailContent = `修正に進みます。\n(修正対応時間：${formattedTime})`;
				this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}] 修正を選択しました`;
				this.isSendCreator = true;
				sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
					this.projectInfo['responsible_user'],
				]);
				this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 修正を選択しました`;
				this.mailContent = `修正に進みます。\n(修正対応時間：${formattedTime})`;
				this.subject = `【CoMoDe】[${this.projectInfo['project_name']}] 修正を選択しました`;
				this.isSendCreator = false;
				sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
					this.projectInfo['client_id'],
				]);
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		onOpenMenuButtonClick() {
			this.$bvModal.show(`${this.openMenuModalId}`);
			this.openMenuModalContents = OPEN_MENU_NOTI_CONTENTS;
		},

		async onOpenMenu() {
			this.$store.commit('setIsAppProcessing', true);

			this.showOptionMenu = true;
			let updateProposalGeneralContent = {
				status: CommonConst.SCREEN_STATUS.MENU_OPEN,
			};

			await this._updateReProposalGeneral(this.projectId, updateProposalGeneralContent);
			socketClient.send('open_option_menu', {
				projectId: this.projectId,
				userId: this.userId,
				content: {
					isOpen: true,
					hourSelected: this.hourSelected,
					minuteSelected: this.minuteSelected,
				},
			});

			await this._getAllReProposalData(this.projectId);

			this.descriptionSkip = '';

			this.$store.commit('setIsAppProcessing', false);
		},

		onClickSendButton() {
			this.$bvModal.show(`${this.sendContentModalId}`);
		},

		onRevisionDeadlineChange(data) {
			console.log(data);
			if (
				typeof data.minuteSelected === 'object' ||
				typeof data.hourSelected === 'object'
			) {
				this.hourSelected = '';
				this.minuteSelected = '';
			} else {
				this.hourSelected = data.hourSelected;
				this.minuteSelected = data.minuteSelected;
			}
		},

		async onRegister(data) {
			this.$store.commit('setIsAppProcessing', true);

			let proposalContent = {
				status: 0,
				supplement: '',
				evaluation: 0,
				comment: data.comment,
				image_file_name: data.imageKey,
				project_id: this.projectId,
				title: data.title,
				update_user: this.userId,
			};
			console.log({ proposalContent });
			let response = await ReProposalService.post({
				doc_content: proposalContent,
			});
			if (response.status !== 200) {
				this.$store.commit('setIsAppProcessing', false);
				throw 'Post proposal failed';
			}

			await this._getReProposalData(this.projectId);

			this.$store.commit('setIsAppProcessing', false);
		},

		async onSelect(data) {
			this.$store.commit('setIsAppProcessing', true);

			// console.log(data);
			this.isSelect = !this.isSelect;
			const proposalList = [...this.proposalData];
			const selectProposalObj = proposalList.find((x) => x.id === data['id']);
			const selectProposalImageURL = await this._getPreviewImgUrl(
				selectProposalObj['image_file_name'],
				this.expiration
			);
			const selectProposalImageKey = selectProposalObj['image_file_name'];

			const selectProposal = {
				title: selectProposalObj['title'],
				comment: selectProposalObj['comment'],
				imageUrl: selectProposalImageURL,
				imageKey: selectProposalImageKey,
				id: selectProposalObj['id'],
				fileType: getFileExtention(selectProposalObj['image_file_name']),
				fileName: getFileName(selectProposalObj['image_file_name']),
			};

			this.selectProposal = selectProposal;
			this.$bvModal.show('create-edit-modal');

			this.$store.commit('setIsAppProcessing', false);
		},

		onCreate() {
			this.isOpen = !this.isOpen;
			this.$bvModal.show('create-edit-modal');
		},

		async onSend(data) {
			this.$store.commit('setIsAppProcessing', true);

			let updateContents = [];
			this.proposalData.forEach((item) => {
				if (item['is_save'] === UNSAVE_NUMBER) {
					let updateContent = {
						id: item['id'],
						is_save: SAVED_NUMBER,
					};
					updateContents.push(updateContent);
				}
			});
			this._updateBulkReProposals(updateContents);

			let updateProposalGeneralContent = this.isRevisionEnabled
				? {
						status: CommonConst.SCREEN_STATUS.SEND,
						hours: this.hourSelected,
						minutes: this.minuteSelected,
				  }
				: {
						status: CommonConst.SCREEN_STATUS.SEND,
				  };

			await this._updateReProposalGeneral(this.projectId, updateProposalGeneralContent);
			await this._getAllReProposalData(this.projectId);

			this.descriptionSkip = '';

			socketClient.send('update_proposal', {
				projectId: this.projectId,
				userId: this.userId,
				content: {
					status: true,
				},
			});
			console.log(data);
			// send mail to client case 50
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 再提案が届きました`;
			this.mailContent = `再提案が届きました。`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}] 再提案が届きました`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);

			this.$store.commit('setIsAppProcessing', false);
		},

		async onUpdate(data) {
			this.$store.commit('setIsAppProcessing', true);

			let proposalId = data.id;
			let updateContent = {
				comment: data.comment,
				image_file_name: data.imageKey,
				project_id: this.projectId,
				title: data.title,
				update_user: this.userId,
			};

			console.log({ updateContent });
			let response = await ReProposalService.update(proposalId, updateContent);
			if (response.status !== 200) {
				this.$store.commit('setIsAppProcessing', false);
				throw 'Update proposal failed';
			}

			await this._getReProposalData(this.projectId);

			this.$store.commit('setIsAppProcessing', false);
		},

		/**
		 * Upload Image to S3
		 * @param {Object} data
		 */
		async onUploadImage(data) {
			this.uploadImageKey = data.fileKey;
			// this.newImageUploaded = true;
		},

		/**
		 * Upload image
		 * @param {Object} params request objects
		 * @returns uploaded file key
		 */
		async _uploadImage(params) {
			try {
				let response = await TransferService.post(params);
				if (!response || response.status !== 200) {
					throw 'Upload Image failed!';
				}

				console.log('%c Upload image successfully!', 'color: red');
				let key = response.data.key;
				return key;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Get reproposal count down date and time from lastest addcost detail item
		 */
		async _getReProposalCountDownData() {
			const estimateParticularResponse = await EstimateParticularService.get(
				this.projectId
			);
			if (estimateParticularResponse.status !== 200) {
				throw 'Get estimate particular failed';
			}
			const quoteId = estimateParticularResponse.data['estimate_particular']['id'];
			const addCostDetailsLastItem = await this._getAddCostDetailLastItem(quoteId);
			this.lastAddCostItemId = addCostDetailsLastItem?.id;
			if (addCostDetailsLastItem?.reproposal_date) {
				this.counterDate = addCostDetailsLastItem?.reproposal_date;
			}
		},

		async _getAddCostDetailLastItem(quoteId) {
			const addCostDetailsResponse = await AddCostDetailService.get(quoteId);
			if (addCostDetailsResponse.status !== 200) {
				throw 'Get addcost detail failed';
			}
			const addCostDetails = addCostDetailsResponse.data;
			const addCostDetailsLastItem = addCostDetails[addCostDetails.length - 1];
			return addCostDetailsLastItem;
		},

		async _getReProposalData(projectId) {
			let reProposalResponse = await ReProposalService.get(projectId);
			if (reProposalResponse.status !== 200) {
				throw 'Get proposal failed';
			}

			this.proposalData = reProposalResponse.data;
			this.proposalList = this._filterProposalList(this.proposalData);
		},

		async _getReProposalGeneralData(projectId) {
			let response = await ReProposalGeneralService.get(projectId);
			if (response.status !== 200) {
				throw 'Get proposal general failed';
			}
			if (this._isObjectEmpty(response.data)) {
				let proposalGeneral = {
					project_id: this.projectId,
					update_user: this.userId,
					hours: null,
					status: '',
					minutes: null,
				};

				await ReProposalGeneralService.post(proposalGeneral);

				this.isShowDeleteButton = true;
				this.revisionDeadlineShow = true;
				this.revisionFlag = ProposalConst.REVISION_INIT_NUM;
				this._checkScreenMode(RE_PROPOSAL_MODE_NUMB);

				// await this._updateHearingsStatus(this.projectId, this.hearingStatus)
				return;
			}

			if (response.data.hours === null) {
				this.hourSelected = '';
				this.minuteSelected = '';
			} else {
				this.hourSelected = response.data.hours;
				this.minuteSelected = response.data.minutes;
				this.deadlineEditAble = false;
			}
			if (response.data.countdown_flag == 1) {
				this.countdownDate = moment(response.data.date_end).format('YYYY/MM/DD HH:mm');
				this.countdownFlag = true;
			}

			this.revisionFlag = response.data.revision_flag;
			this.revisionDeadlineShow = true;
			this.descriptionLabel = DESCRIPTION_LABEL;
			this.proposalStatus = response.data.status;
			this._checkProposalStatus(this.proposalStatus);
			this.screenMode = response.data.screen_mode;
			this._checkScreenMode(this.screenMode);
		},

		async _getAllReProposalData(projectId) {
			await this._getReProposalData(projectId);
			await this._getReProposalGeneralData(projectId);
		},

		/**
		 * Get data from Add Cost Details Table
		 * @param {String} detailId add cost detail id
		 * @param {Object} addCostDetail add cost detail content
		 * @returns Add Cost Details 's Data
		 */
		async _updateAddCostDetails(detailId, addCostDetail) {
			try {
				let response = await AddCostDetailService.update(detailId, addCostDetail);
				if (response && response.status === 200) {
					console.log('Update Addcost detail successfully!');
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(error);
			}
		},

		_checkProposalStatus(status) {
			if (status === CommonConst.SCREEN_STATUS.SEND) {
				this.deadlineEditAble = false;
				this.allowAdditions = false;
			} else if (status === CommonConst.SCREEN_STATUS.MENU_OPEN) {
				this.deadlineEditAble = false;
				this.allowAdditions = false;
				this.showOptionMenu = true;
			} else if (status === CommonConst.SCREEN_STATUS.FINISH) {
				this.showFullOptionMenu = false;
				this.deadlineEditAble = false;
				this.allowAdditions = false;
				this.revisionDeadlineShow = false;
				this.proposalDeadlineShow = false;
				this.descriptionLabel = '';
				this.descriptionSkip = '';
			} else if (status === CommonConst.SCREEN_STATUS.REVISION) {
				this.showOptionMenu = false;
				this.allowAdditions = true;
			} else if (status === '') {
				this.deadlineEditAble = true;
			}

			this.isShowDeleteButton = !status;
		},

		_checkScreenMode(screenMode) {
			if (screenMode === RE_PROPOSAL_MODE_NUMB) {
				this.showFullOptionMenu =
					this.isRevisionEnabled && !this.isRevisionDeadlineInit ? true : false;
				this.sendItemBtnName = 'ご提案を送信';
				this.sendContentModalContents = PROPOSAL_SELECTIVE_NOTI_CONTENTS;
			} else if (screenMode === RE_REVISION_MODE_NUMB) {
				this.screenName = CommonConst.RE_REVISION_SCREEN_NAME;
				this.showFullOptionMenu = false;
				this.sendItemBtnName = '修正を送信';
				this.sendContentModalContents = REVISION_SELECTIVE_NOTI_CONTENTS;
			}
		},

		_filterProposalList(proposalData) {
			let proposalList = [];

			for (const element of proposalData) {
				let defaultProposal = { ...DEFAULT_ITEM };
				defaultProposal['gridCols'] = 3;
				defaultProposal['gridStatus'] = 'normal';
				defaultProposal['itemId'] = element['id'];
				defaultProposal['nonfilterDateData'] = element['date_created'];
				defaultProposal['title'] = this._renameItem(
					element['title'],
					element['date_created']
				);

				let isItemSaved = Boolean(element['is_save']);

				if (
					!isItemSaved &&
					this.proposalStatus === CommonConst.SCREEN_STATUS.REVISION
				) {
					defaultProposal['isShowIcon'] = false;
					defaultProposal['dateData'] = '';
				} else {
					defaultProposal['isShowIcon'] = true;
					defaultProposal['dateData'] = '提案済み';
				}

				proposalList.push(defaultProposal);
			}
			return proposalList;
		},

		_renameItem(title, date) {
			if (title && date) {
				let momentDate = moment(date);
				let year = momentDate.format('YYYY');
				let month = momentDate.format('MM');
				let day = momentDate.format('DD');
				return `「${year}年${month}月${day}日」: ${title}`;
			} else {
				return title;
			}
		},

		async _getPreviewImgUrl(imgKey, expiration) {
			try {
				let response = await TransferService.get(imgKey, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: red');
				let imgUrl = response.data.link;
				return imgUrl;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Update bulk contents
		 * @param {Array} contents update contents
		 */
		async _updateBulkReProposals(contents) {
			let response = await ReProposalService.updateBulk(contents);
			if (response && response.status === 200) {
				console.log('%c Update Bulk Proposal successfully!', 'color: green');
			} else {
				throw response.message;
			}
		},

		/**
		 * Update ReProposal General
		 * @param {Integer} projectId Project ID
		 * @param {Object} content updated content
		 */
		async _updateReProposalGeneral(projectId, content) {
			try {
				let response = await ReProposalGeneralService.put(projectId, content);
				if (!response || response.status !== 200) {
					throw 'Update Proposal General failed!';
				}

				console.log('%c Update Proposal General successfully!', 'color: green');
			} catch (error) {
				console.log(error);
			}
		},

		_isObjectEmpty(obj) {
			for (var x in obj) {
				return false;
			}
			return true;
		},

		async deleteProposal(proposalId) {
			try {
				const response = await ReProposalService.delete(proposalId);
				if (!response || response.status !== 200) {
					throw 'Delete Proposal failed!';
				}

				console.log('%c Delete Proposal successfully!', 'color: green');
			} catch (error) {
				console.log(error);
			}
		},
	},

	async created() {
		socketClient.listen('new_proposal_updated', async (data) => {
			if (data) {
				this.$store.commit('setIsAppProcessing', true);

				await this._getAllReProposalData(this.projectId);

				this.$store.commit('setIsAppProcessing', false);
			}
		});
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		moment.locale('ja');
		await this._getAllReProposalData(this.projectId);
		await this._getReProposalCountDownData();

		this.$store.commit('setIsAppProcessing', false);
	},

	beforeDestroy() {
		socketClient.removeAllListeners('new_proposal_updated');
	},
};
